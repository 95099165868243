import { useMemo } from 'react';
import { getTheme } from '@gcc/autobahn-components';

const useFixedHarmonyTheme = () => useMemo(() => {
  const harmonyTheme = getTheme('harmony');

  return harmonyTheme;
}, []);

export default useFixedHarmonyTheme;
